@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    src: local('Barlow Regular'), local('Barlow-Regular'), url('./assets/font/Barlow-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    src: local('Barlow Bold'), local('Barlow-Bold'), url('./assets/font/Barlow-Bold.ttf') format('truetype');
}

@import "~@ng-select/ng-select/themes/default.theme.css";

.container {
    max-width: 2560px !important; /* Override default container width */
}

.list-modules {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 4px;
  }

.fc-timegrid-event-harness>.fc-timegrid-event {
    inset: 0px -2% 0% 0%;
    position: absolute;
}

.fc-v-event {
    background: none !important;
    border: none !important;
    // width: calc(100vw - 64px);
}

.fc .fc-timegrid-col.fc-day-today {
    background: inherit !important;
}


.break-tag {
    border-left: 3px solid #0EA5E9;
    background: rgba(14, 165, 233, 0.10);
    color: #0369A1;
    cursor: pointer;
}

.theory-tag {
    border-left: 3px solid #2B3C3D;
    background: rgba(117, 156, 159, 0.10);
    color: #2B3C3D;
    cursor: pointer;
}

.practical-tag {
    border-left: 3px solid #6D28D9;
    background: rgba(139, 92, 246, 0.10);
    color: #6D28D9;
    cursor: pointer;
}

.option-more {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    position: absolute;
    right: 70px;
    z-index: 2;

    .content {
        display: flex;
        width: 163px;
        padding: 11px 8px;
        align-items: flex-start;
        gap: 4px;
        border-radius: 4px;
        background: #FFFFFF;

    }

    .content:hover {
        background: #D4E5E5;
    }
}


.password-row {
    display: flex;
    flex-direction: row;
    // align-items: center;
    align-self: stretch;
    width: 100%;
    height: 48px;
    justify-content: space-between;
    // background-color: #F4F4F4;
}

.password-bar {
    display: flex;
    align-items: center;
    margin-top: 0px;
    // height: 32px;
    width: 100%;
    background-color: #F4F4F4;
}

.password-icon {
    margin-left: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.login-input {
    background-color: #F4F4F4;
    height: 48px;
    width: 100%;
    border: none;
    padding: 12px;
    // background-color: transparent;
    border: transparent;
}

.login-input-internal-autofill-selected {
    background: #F4F4F4 !important;
}

.login-input:focus {
    background: #F4F4F4;
    height: 48px;
    width: 100%;
    outline: none;
    padding: 12px;
}

.button {
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(204, 206, 208, 0.16);
    font-size: 12px;
    font-weight: 500;


    .btn-export {
        background: var(--background-background, #FFF);
        color: var(--Primary, #2B3C3D);
        padding: 12px;
        margin-right: 8px;
    }

    .btn-add {
        background: var(--Primary, #2B3C3D);
        color: #DF5;
        padding: 8px;
    }

}

body {
    font-family: 'Barlow', sans-serif !important;
    overflow: hidden;
}

label {
    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    span {
        color: var(--text-text-error, #DA1E28);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }
}

select.form-select {
    border-radius: 4px;
    border: 0px solid #8D8D8D;
    background-color: #F1F5F5;
    width: 100px;
    height: 40px;
    padding: 10px;
    margin-bottom: 5px;
}

.textarea {
    border-radius: 4px;
    border: 0px solid var(--border-border-strong-01, #8D8D8D);
    background: var(--Input-Field, #F1F5F5);
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;

}

.pdf-modal-width .modal-dialog .modal-content {
    width: 90vw !important;
    height: 55vw !important;
}

.pdf-modal-width .modal-dialog {

    margin-left: 50px !important;
    margin-right: 50px !important;
    max-width: 100vw !important;

}

.banner {
    margin-bottom: 20px;

    img {
        width: 100%;
        height: 200px;
        object-fit: cover
    }
}

// input[type="text"]::placeholder {
//     color: var(--text-text-placeholder, #A8A8A8);
//     font-size: 16px;
//   }

.h-100 {
    height: 100% !important;
}

.fc th {
    display: none !important;
}

.input {
    border-radius: 4px;
    border: 0px solid #8D8D8D;
    background: #F1F5F5;
    width: 100%;
    height: 45px;
    padding: 10px;
    margin-bottom: 5px;
}

.number-status {
    border-radius: 8px;
    background: rgba(117, 156, 159, 0.20);
    display: flex;
    height: 20px;
    width: 25px;
    justify-content: center;
    align-items: center;
    color: #161616;
    font-size: 12px;
}

.section-bottom {

    border-radius: 8px;
    margin-top: 30px;
    min-height: 70vh;
    background: var(--background-background, #FFF);

    .container {
        padding: 15px 10px !important;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
    }
}

.sort-dropdown {
    margin-right: 10px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    padding: 8px;
    //   width       : 140px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;


    select {
        border: none;
    }
}

.search-container {
    position: relative;
    width: auto;

}

.search-input {
    border-radius: 4px;
    padding: 9px 8px 9px 30px;
    border: 1px solid #C6C6C6;


    background: url('./assets/icon/search.svg') no-repeat left center;
    background-size: 33px 20px;
    /* Adjust the size based on your design */
    border: 1px solid #ccc;
    /* Add styling for the input border if needed */
}

.module-header {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    justify-content: space-between;
    width: 100%;

}

.module-footer {
    display: flex;
    align-items: flex-start;
    gap: 1px;
    align-self: stretch;

    .cancel {
        display: flex;
        padding: 12px 13px 28px 13px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        cursor: pointer;
    }

    .confirm {
        cursor: pointer;
        display: flex;
        padding: 12px 13px 28px 13px;
        align-items: center;
        gap: 8px;
        border: none;
        align-self: stretch;
        color: white;
        background: #2B3C3D;
    }

    .delete {
        cursor: pointer;
        display: flex;
        padding: 12px 13px 28px 13px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        color: white;
        background: #DA1E28;
    }

    .disable {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.module-content {
    display: flex;
    padding: 0px 16px 48px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;

    .desc {
        color: #525252;
    }

    .form-module {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        div span {
            color: red;
        }
    }
}

.other-label {
    font-size: 16px;
    //   color: #222631;
    color: #8D8D8D;
}

.acc-label {
    font-size: 20px;
    font-weight: 500;
}

.horizontal-line {
    height: 0.5px;
    background: #e0e0e0;
    width: 100%;
}


.input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.profile-desc {
    color: #222631;
}



.input {
    padding-right: 50px;
    width: 100%;
}

.border {
    width: 1px;
    height: 17px;
    background: #E0E0E0;
}

.icons {
    position: absolute;
    bottom: 20px;
    right: 10px;
    display: flex;
    gap: 5px;
}

.icons img {
    cursor: pointer;
    height: 17px;
}


.section-bottom-course {
    border-radius: 8px;
    margin-top: 9px;
    padding-bottom: 20px;
    height: 68vh;
    background: transparent;

    .tabs {
        display: flex;
        border-bottom: 1px solid #ccc;
    }

    .tab {
        flex: 1;
        padding: 10px;
        cursor: pointer;
        border-bottom: 2px solid #E0E0E0;
        background: transparent;
        border-radius: 5px 5px 0 0;

    }

    .tab.active {
        color: var(--text-text-primary, #161616);
        border-bottom: 2px solid #2B3C3D;
        background: transparent;
    }

    .tab-content {
        border-radius: 0 0 5px 5px;
        margin-top: 20px;
    }
}

.section-top {
    p {
        color: var(--text-text-primary, #161616);
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .fa,
    .fa-solid {
        margin-right: 5px;
    }

}

.session-list {
    padding: 16px;
    border-radius: 4px;
    border: 0.5px solid #E0E0E0;
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
    margin-top: 10px;

    .seat-available {
        overflow: hidden;
        color: #DA1E28;
        text-overflow: ellipsis;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .trainers {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 12px;
        font-weight: 400px;
    }

    .no-trainers {
        font-size: 12px;
        font-weight: 400px;
        color: #A8A8A8;
        font-style: italic;
    }
}

table {
    width: 100%;

    thead {
        //   background: rgba(117, 156, 159, 0.10);
        background: #f1f5f5;
        font-size: 14px;
        color: #525252;

        tr th {
            padding: 14px 16px;
            white-space: nowrap;
            font-style: normal;
            font-weight: 500;
        }
    }

    tbody {
        tr {
            border-bottom: 0.5px solid #E0E0E0;


        }

        tr:hover {
            background: rgba(117, 156, 159, 0.10);
            cursor: pointer;

        }

        td {
            padding: 14px 16px;
            color: #161616;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;

        }

        .d-flex {
            img {
                width: 28px;
                height: 28px;
                margin: 5px;
            }
        }

        span {
            font-size: 12px;
            color: #A8A8A8;
        }
    }
}

.footer {
    margin-bottom: 30px;
    padding: 10px;
    bottom: 0;
    color: #161616;
    font-size: 12px;
    font-weight: 400;

    span {
        font-weight: 500;

    }

    .circle-number {
        border-radius: 100px;
        background: #2B3C3D;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.00);
        width: 20px;
        height: 20px;
        margin: 2px 6px;
        color: #EEF4F7;
        letter-spacing: 0.5px;

        span {
            margin: 7px;
        }



    }

    button {
        border: none;
        background: transparent;
    }
}

.days {
    display: flex;
    // justify-content: center;
    width: 100%;
    overflow-x: auto;
    align-items: center;
    // gap: 12px;
    align-self: stretch;
    font-size: 12px;

    div .container-days {
        display: flex;
        width: 100%;
        padding: 8px 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 4px;
        border: 0.5px solid rgba(43, 60, 61, 0.40);
        color: black;
        cursor: pointer;
    }

    div .container-days-active {
        display: flex;
        width: 100%;
        padding: 8px 12px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 4px;
        border: 0.5px solid rgba(43, 60, 61, 0.40);
        background-color: #2B3C3D;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        color: white;
    }
}

.upload {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;

    .cont {
        display: flex;
        height: 175px;
        padding: 20px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px dashed #E0E0E0;
        background: #FFF;
    }
}

.access {
    margin: 0px 10px;
}

.add-modules {
    display: flex;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed var(--Primary-Alphaa-Beast-400, rgba(100, 116, 139, 0.40));
    cursor: pointer;
}

.progress {
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-top: 10px;
    height: 10px !important;
}

.progress-bar {
    background-color: #2B3C3D !important;
    height: 10px;
    border-radius: 5px;
}

.file-container {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
}

.file-item {
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--primary-10, rgba(117, 156, 159, 0.10));

    .file-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
    }
}

.preview-text {
    color: #0F62FE;
    font-size: 12px;
    cursor: pointer;
    margin-left: 3px
}

.header-booking {
    background: #F4F4F4;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    align-self: stretch;
    color: black;
    font-family: Barlow;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    margin-top: 63px;

}

.session-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.session-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.view-buttons {
    width: auto;
    border: 1px solid #E0E0E0;
    border-radius: 4px;

    button {
        border: none;
        padding: 10px 12px;
    }
}

.view-buttons button.active {
    background-color: white;
}

.button-status-active {
    border-radius: 24px;
    padding: 0px 8px 2px 8px;
    background-color: #A7F0BA;
    color: #0E6027;
    text-align: center;
}

.button-status-upcoming {
    border-radius: 24px;
    padding: 0px 8px 2px 8px;
    background-color: #FDDDBC;
    color: #795C24;
    text-align: center;
}

.button-status-ongoing {
    border-radius: 24px;
    padding: 0px 8px 2px 8px;
    background-color: rgba(0, 67, 206, 0.3);
    color: #0043CE;
    text-align: center;
}

.module-layout {
    display: flex;
    background-color: rgba(117, 156, 159, 0.2);
    border-radius: 6px;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 15px;
}

.label-desc {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px;
}

.file-container {
    display: flex;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 4px;
    background: rgba(117, 156, 159, 0.10);
}

.preview-btn {
    display: flex;

    gap: 4px;
    border-radius: 4px;
    color: #DDFF55;
    cursor: pointer;
    background: #2B3C3D;
}

.next {
    border-radius: 4px;
    background: #2B3C3D;
    display: flex;
    // width: 100px;
    height: 36px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    color: #DF5;
    cursor: pointer;
   

}

.absent-btn {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #2B3C3D;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    color: #2B3C3D;
}

.btn-disabled {
    border-radius: 4px;
    border: 1px solid #BABFC7;
    background: #E5E5E5;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    
}

.disable-file {
    color: #A8A8A8;
}

.normal-file {
    color: #161616;
}

